<template>
	<div class="container">
		<section class="header-back">
			<img class="bk2" src="@/assets/bk2.png" alt="bk" />
			<div class="header">
				<!--<img class="logo" src="@/assets/logo.png" alt="logo" />-->
				<p class="title">HAS医院患者全流程管理</p>
				<img class="pc1" src="@/assets/pic1.png" alt="logo" />
				<div class="flex-end">
					<div class="code-conent">
						<img class="code" src="@/assets/code2.png" alt="logo" />
						<p class="fs22 mt32">扫码进入全流程管理平台</p>
					</div>
				</div>
			</div>
		</section>
		<section>
			<p class="fs36 mt80">HAS患者全流程服务</p>
			<p class="fs18 grey9 mb68">
				——— Whole process service of has patients ———
			</p>
		</section>
		<ul class="tabs sk">
			<li
				class="tabitem"
				:class="this.currentIndex === 0 && 'active-tab'"
				@click="changeTab(0)"
			>
				<a href="#tab1">电子陪护证</a>
			</li>
			<li
				class="tabitem"
				:class="this.currentIndex === 1 && 'active-tab'"
				@click="changeTab(1)"
			>
				<a href="#tab2">智能随访</a>
			</li>
			<li
				class="tabitem"
				:class="this.currentIndex === 2 && 'active-tab'"
				@click="changeTab(2)"
			>
				<a href="#tab3">互联网+护理</a>
			</li>
			<li
				class="tabitem"
				:class="this.currentIndex === 3 && 'active-tab'"
				@click="changeTab(3)"
			>
				<a href="#tab4">患者管理</a>
			</li>
		</ul>
		<section id="tab1" ref="tab1">
			<div class="content justify-between">
				<div class="mt261 tl">
					<p class="fs36 grey3 bold">电子陪护证</p>
					<p class="fs28 grey6 mt30 lh50">智慧陪护全流程管理平台</p>
					<p class="fs28 grey6 lh50">病区管理数字化规范化</p>
				</div>
				<img class="mt106 pic2" src="@/assets/pic2.png" alt="logo" />
			</div>
		</section>
		<section id="tab2" ref="tab2">
			<div class="content justify-between">
				<img class="mt244 pic3" src="@/assets/pic3.png" alt="logo" />
				<div class="mt261 tl">
					<p class="fs36 grey3 bold tr">智能随访</p>
					<p class="fs28 mt30 ml46 grey6 lh50">为患者提供个性化的提醒</p>
					<p class="fs28 ml46 grey6 lh50">
						（复诊、用药、生活、健康宣教、活动通知）
					</p>
					<p class="fs28 ml46 grey6 lh50">
						帮助患者康复，根据病种制定随访计划，AI智能提醒
					</p>
				</div>
			</div>
		</section>
		<section id="tab3" ref="tab3">
			<div class="tl mt244 content fdc">
				<p class="fs36 bold grey3">互联网+护理</p>
				<p class="fs28 grey6 mt32 lh50">“线上申请、线下服务”</p>
				<p class="fs28 grey6 lh50">
					为出院患者或罹患疾病且行动不便的 特殊人群提供居家护理服务
				</p>
			</div>
			<img class="pic4" src="@/assets/pic4.png" alt="logo" />
		</section>
		<section id="tab4" ref="tab4">
			<div class="content fdc mt80">
				<p class="fs36 bold tl">患者管理</p>
				<p class="fs28 grey6 tl mt32 lh50">
					从门诊到住院，医生通过企业微信与微信患者建立1对1联系，
				</p>
				<p class="fs28 grey6 tl lh50">以科室为单位实现精细化患者管理</p>
			</div>
			<div class="mt63 content justify-between">
				<img class="pic5 ml46" src="@/assets/pic5.png" alt="logo" />
				<div class="tl fdc ml168">
					<p class="grey3 fs36">精准用户画像</p>
					<p class="grey6 fs25 mt16 lh40">
						打通HIS和小程序、服务号、用户属性一目了然
					</p>
					<p class="grey3 fs36 mt70">多种沟通方式</p>
					<p class="grey6 fs25 mt16 lh40">
						1对1消息、群运营、客户朋友圈，多种方式触达 患者，配合标签更精准
					</p>
					<p class="grey3 fs36 mt70">多种服务工具</p>
					<p class="grey6 fs25 mt16 lh40">
						赋能医务人员、多种工具提升管理效率、降低沟通成本
					</p>
				</div>
			</div>
		</section>
		<section>
			<img class="pic6 mt261" src="@/assets/pic6.png" alt="logo" />
			<p class="grey3 fs36 fw600 tag1">全流程覆盖</p>
			<p class="grey3 fs24 fw600 tag2">精细化患者管理</p>
			<p class="grey3 fs24 fw600 tag3">提升患者体验</p>
			<p class="grey3 fs24 fw600 tag4">数字化流程再造</p>
			<p class="grey3 fs24 fw600 tag5">线上线下相结合的患者智慧服务</p>
		</section>
		<section class="footer">
			<div class="ftop">
				<div class="tl">
					<p class="white fs18">联系我们</p>
					<p class="grey9 fs16 mt30">咨询电话：4000123411</p>
					<p class="grey9 fs16 mt20">联系邮箱：linchao@100healthcare.cn</p>
					<p class="grey9 fs16 mt20">
						公司地址：浙江省杭州市余杭区五常街道五常大道181号10幢502室
					</p>
				</div>
				<!--<img class="code2" src="@/assets/code.png" alt="医百分小助手" />-->
			</div>
			<p class="grey9 fs16 mt96">
				Copyright © 2019-2020 CarryTech.All Right Reserved.医百分科技有限公司
				版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2020038933号-2</a>
			</p>
		</section>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentIndex: 0,
		};
	},
	mounted() {
		window.addEventListener('scroll', this.handleScrollx, true);
	},
	methods: {
		changeTab(idx) {
			this.currentIndex = idx;
		},
		handleScrollx() {
			const distanceTab1 = this.$refs.tab1.getBoundingClientRect().top;
			const distanceTab2 = this.$refs.tab2.getBoundingClientRect().top;
			const distanceTab3 = this.$refs.tab3.getBoundingClientRect().top;
			const distanceTab4 = this.$refs.tab4.getBoundingClientRect().top;
			if (distanceTab1 <= 0 && distanceTab2 > 0) {
				this.currentIndex = 0;
			} else if (distanceTab2 <= 0 && distanceTab3 > 0) {
				this.currentIndex = 1;
			} else if (distanceTab3 <= 0 && distanceTab4 > 0) {
				this.currentIndex = 2;
			} else if (distanceTab4 <= 0) {
				this.currentIndex = 3;
			}
		},
	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	min-width: 1625px;
}
* {
	padding: 0;
	margin: 0;
}
.fs22 {
	font-size: 22px;
}
.fs16 {
	font-size: 16px;
}
.fs18 {
	font-size: 18px;
}
.fs24 {
	font-size: 24px;
}
.fs25 {
	font-size: 25px;
}
.fs28 {
	font-size: 28px;
}
.fs36 {
	font-size: 36px;
}
.lh40 {
	line-height: 40px;
}
.lh50 {
	line-height: 50px;
}
.bold {
	font-weight: 500;
}
.fw600 {
	font-weight: 600;
}
.ml46 {
	margin-left: 46px;
}
.ml168 {
	margin-left: 168px;
}
.mt16 {
	margin-top: 16px;
}
.mt20 {
	margin-top: 20px;
}
.mt30 {
	margin-top: 30px;
}
.mt32 {
	margin-top: 32px;
}
.mt96 {
	margin-top: 96px;
}
.mt70 {
	margin-top: 70px;
}
.mt80 {
	margin-top: 80px;
}
.mt261 {
	margin-top: 261px;
}
.mt244 {
	margin-top: 244px;
}
.mt106 {
	margin-top: 106px;
}
.mb68 {
	margin-bottom: 68px;
}
.mt63 {
	margin-top: 63px;
}
.grey3 {
	color: #333;
}
.grey6 {
	color: #666;
}
.grey9 {
	color: #999;
}
.white {
	color: white;
}
.tl {
	text-align: left;
}
.tr {
	text-align: right;
}
.flex-start {
	display: flex;
	align-self: flex-start;
}
.flex-end {
	display: flex;
	justify-content: flex-end;
}
.flex-center {
	display: flex;
	justify-content: center;
}
.fdc {
	display: flex;
	flex-direction: column;
}
.justify-between {
	justify-content: space-between;
}
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
section {
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.footer {
	padding: 88px 288px 46px 288px;
	height: 411px;
	background-color: #333;
	box-sizing: border-box;
}
.ftop {
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
}
.code2 {
	width: 125px;
	height: 125px;
}
.header-back {
	background: linear-gradient(90deg, #2441af 0%, #3988f4 100%);
}
.bk1 {
	width: 624px;
	height: 581px;
}
.bk2 {
	position: absolute;
	top: 145px;
	/* left: 100px; */
	width: 1523px;
	height: 903px;
}
.bk3 {
	position: absolute;
	top: 169px;
	right: 0;
	width: 620px;
	height: 517px;
}
.header {
	box-sizing: border-box;
	width: 1345px;
	height: 1080px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.logo {
	margin-top: 60px;
	width: 268px;
	height: 78px;
}
.title {
	font-size: 60px;
	font-weight: 500;
	color: #fff;
	margin: 0;
	margin-top: 90px;
	display: flex;
	justify-content: flex-end;
}
.pc1 {
	width: 922px;
	height: auto;
	margin-top: -100px;
}
.pic2 {
	width: 782px;
	height: 580px;
}
.pic3 {
	width: 674px;
	height: 378px;
	border: 17px solid #f0f2f5;
	box-shadow: 9px 9px 12px 0px rgba(0, 0, 0, 0.11);
	border-radius: 16px;
}
.pic4 {
	width: 955px;
	height: 595px;
}
.pic5 {
	width: 516px;
	height: 548px;
}
.pic6 {
	width: 590px;
	height: 660px;
}
.code-conent {
	background-color: #fff;
	width: 358px;
	height: 454px;
	border-radius: 32px 32px 0px 0px;
	margin-top: -404px;
}
.code {
	width: 332px;
	height: 332px;
	border: 1px dashed #666;
	margin-top: 20px;
}
.sk {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #fff;
}
.tabs {
	margin: 0 auto;
	width: 1346px;
	border-top: 1px solid #f0f0f0;
	border-bottom: 1px solid #f0f0f0;
}
.tabitem {
	display: inline-block;
	height: 80px;
	width: 270px;
	line-height: 80px;
	font-size: 22px;
}
.active-tab {
	background-color: #00c8f7;
	color: #fff;
}
.content {
	position: relative;
	display: flex;
	width: 1346px;
}
.tag1 {
	position: relative;
	top: -500px;
	right: 420px;
}
.tag2 {
	position: relative;
	left: 380px;
	top: -526px;
}
.tag3 {
	position: relative;
	left: 457px;
	top: -340px;
}
.tag4 {
	position: relative;
	top: -236px;
	left: 300px;
}
.tag5 {
	position: relative;
	top: -264px;
	right: 440px;
}
a {
	color: inherit;
}
</style>
