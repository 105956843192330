import { createRouter, createWebHistory } from 'vue-router';

const routes = [];

const router = createRouter({
	history: createWebHistory('/'),
	routes,
});

export default router;
